/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    // document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    // <div className='d-flex flex-column flex-column-fluid flex-lg-row'>
    //     <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
    //     </div>
    //     <div className='d-flex flex-center w-lg-50 p-10'>
    //         <div className='card rounded-3 w-md-550px'>
    //             <div className='card-body p-10 p-lg-20'>
    //             <Outlet />
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg-3.jpg')})`,
        backgroundSize:'cover'
      }}
    >
      {/* begin::Content */}
      {/* <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'> */}
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.jpg')} className='h-45px' />
        </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
          <Outlet />
        {/* end::Wrapper */}
      {/* </div> */}
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
