export const productsModule = {
    productsList: [
        {
            id: 1457690400,
            sku:'JB005',
            category_id:1,
            category:'Printed Labels',
            name: 'Transfer Print Sticker',
            description: '56 CM',
            size: 'M',
            price: 1001,
            price_bulk: 100,
            photo: '/media/products/printed-label-1.png',
        },
        {
            id: 1457690490,
            sku:'AR946',
            category_id:1,
            category:'Printed Labels',
            name: 'Heat Transfer Sticker',
            description: 'To be used on dark base garments',
            size: 'L',
            price: 1001,
            price_bulk: 120,
            photo: '/media/products/printed-label-2.png',
        },
        {
            id: 1557690290,
            sku:'LBL003',
            category_id:1,
            category:'Printed Labels',
            name: 'Dark Color Base Fabric',
            description: 'For dark color base fabric',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/printed-label-2.png',
        },
        {
            id: 1456690290,
            sku:'JB001',
            category_id:2,
            category:'Woven Labels',
            name: 'Loop Label',
            description: 'Juniors Baby',
            size: 'XL',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/woven-label-1.png',
        },
        {
            id: 5,
            sku:'JB001',
            category_id:2,
            category:'Woven Labels',
            name: 'Side Stich Label',
            description: 'Juniors Baby',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/woven-label-2.png',
        },
        {
            id: 6,
            sku:'JB001',
            category_id:2,
            category:'Woven Labels',
            name: 'Loop Label',
            description: 'Juniors Baby',
            size: 'S',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/woven-label-3.png',
        },
        {
            id: 7,
            sku:'JB001',
            category_id:2,
            category:'Woven Labels',
            name: 'Jacket Label',
            description: 'Juniors Baby',
            size: 'XS',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/woven-label-4.png',
        },
        {
            id: 8,
            sku:'JB001',
            category_id:2,
            category:'Woven Labels',
            name: 'Main Label',
            description: 'Juniors Baby',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/woven-label-5.png',
        },
        {
            id: 9,
            sku:'JB001',
            category_id:4,
            category:'Tags',
            name: 'Main Tag',
            description: 'Main Tag',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/tag-1.png',
        },
        {
            id: 10,
            sku:'JB001',
            category_id:4,
            category:'Tags',
            name: 'Main Tag',
            description: 'Main Tag',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/tag-2.png',
        },
        {
            id: 11,
            sku:'JB001',
            category_id:4,
            category:'Tags',
            name: 'Main Tag',
            description: 'Main Tag',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/tag-3.png',
        },
        {
            id: 12,
            sku:'JB001',
            category_id:4,
            category:'Tags',
            name: 'Main Tag',
            description: 'Main Tag',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/tag-4.png',
        },
        {
            id: 13,
            sku:'JB001',
            category_id:4,
            category:'Tags',
            name: 'Main Tag',
            description: 'Main Tag',
            size: 'M',
            price: 493,
            price_bulk: 400,
            photo: '/media/products/tag-5.png',
        },
    ]
}