import {FC, useContext} from 'react'
import {ID} from '../../../../../_metronic/helpers'
import AppCartContext from '../../components/AppCartContext'

type attributeValue = {
    id:ID,
    value:string
}
type attributeValues = {
    attribute_values:Array<attributeValue>
}
type Props = {
  id: ID,
  attributes:Array<attributeValues>
}

const ProductAttributeCell: FC<Props> = ({id, attributes}) => {
    // console.log('attributes', attributes.at(0)?.attribute_values);
    const {selectedSize, setSelectedSize} = useContext(AppCartContext);
    // const [product_size, setProductSize] = useState<any>(attributes.at(0)?.attribute_values.at(0)?.value)
  return (
    <div className='form-input form-input-solid'>
        <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setSelectedSize(e.target.value)}
                // value={selectedSize}
                key={`select-attribute-${id}`}
            >
                <option value={''}>--Select Size--</option>
                {
                    attributes && attributes.map((attribute) =>
                        attribute.attribute_values.map(values => <option key={`attribute-${id}-${values.id}`} value={values.value}>{values.value}</option>)
                    )
                }
        </select>
    </div>
  )
}

export {ProductAttributeCell}
