import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProductsListHeader} from './components/header/ProductsListHeader'
import {ProductsTable} from './table/ProductsTable'
// import {CategoryEditModal} from './category-edit-modal/CategoryEditModal'
import {KTCard} from '../../../_metronic/helpers'
import { CartProvider } from 'react-use-cart'
import { CartTable } from './table/CartTable'


const ProductsList = () => {
    //   const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ProductsListHeader />
        <ProductsTable />
      </KTCard>
      <KTCard className='my-3'>
        <div className='card-header border-0 pt-6'>
        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Your Cart</h1>
        </div>
        <CartTable />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <CategoryEditModal />} */}
    </>
  )
}

const ProductsListWrapper = () => {
    return(
        <QueryRequestProvider>
            <QueryResponseProvider>
            <ListViewProvider>
                <CartProvider>
                    <ProductsList />
                </CartProvider>
            </ListViewProvider>
            </QueryResponseProvider>
        </QueryRequestProvider>
        )
}

export {ProductsListWrapper}
