/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { productsModule } from '../../@fake-db';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import FsLightbox from 'fslightbox-react';
let products = productsModule.productsList;
export function ProductsPage() {
    const { category } = useParams<'category'>()
    const [filteredProducts, setFilteredProducts] = useState(products)
    const [toggler, setToggler] = useState(false);
    const filterProducts = (event:any) => {
        let filteredProducts = products.filter((product) => {
            if (product.category_id.toString() === category && event.target.value === "") {
                return product
            }
            else if ((product.category_id.toString() === category) && (product.name.toLowerCase().includes(event.target.value.toLocaleLowerCase())
                || product.sku.toLowerCase().includes(event.target.value.toLocaleLowerCase()))) {
                return product
            }
            else{
                return null
            }
        });
        setFilteredProducts(filteredProducts);
    };

    useEffect(() => {
        const filteredProducts = products.filter((product) => {
            if (product.category_id.toString() === category) {
                return product
            }
            else{
                return null
            }
        });
        setFilteredProducts(filteredProducts);
    }, [category])
    return (
        <>
        <form className='form d-flex flex-column flex-lg-row' action='/order/checkout'>
            {/* begin::Main column */}
            <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                <div className="card card-flush py-4">
                    <div className="card-header">
                        <div className="card-title">
                            <h2>Select Products</h2>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="d-flex flex-column gap-10">
                            {/*begin::Search products*/}
                            <div className="d-flex align-items-center position-relative mb-n7">
                                {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height={2} transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                <input type="text" onChange={filterProducts} className="form-control form-control-solid w-100 w-lg-50 ps-14" placeholder="Search Products" />
                            </div>
                            {/*end::Search products*/}

                            {/*begin::Table*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-5">
                            {/*begin::Table head*/}
                            <thead>
                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th className="min-w-200px">Product</th>
                                    <th className="min-w-200px">Description</th>
                                    <th className="min-w-200px">Size</th>
                                    <th className="min-w-100px text-end">100/pcs price</th>
                                    <th className="min-w-80px pe-5">Qty</th>
                                    <th className='text-end'>Action</th>
                                </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody className="fw-bold text-gray-600">
                                {/*begin::Product=*/}
                                {
                                    filteredProducts.map(product => (
                                        <tr key={product.id}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                            {/*begin::Thumbnail*/}
                                            <a href="#" onClick={() => setToggler(!toggler)} className="symbol symbol-50px overlay">

                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25 shadow">
                                                    <i className="bi bi-eye-fill text-white fs-2x"></i>
                                                </div>
                                                <span className="symbol-label" style={{backgroundImage: `url('${toAbsoluteUrl(product.photo)}'`}} />
                                            </a>
                                            <FsLightbox
                                                toggler={toggler}
                                                // source={toAbsoluteUrl(product.photo)}
                                                sources={[
                                                    toAbsoluteUrl(product.photo),
                                                ]}
                                            />
                                            {/*end::Thumbnail*/}
                                            <div className="ms-5">
                                                {/*begin::Title*/}
                                                <a href="/" className="text-gray-800 text-hover-primary fs-5 fw-bolder">{product.name}</a>
                                                {/*end::Title*/}
                                                {/*begin::Price*/}
                                                <div className="fw-bold fs-7">Price: ₹
                                                <span data-kt-ecommerce-edit-order-filter="price">{product.price}</span></div>
                                                {/*end::Price*/}
                                                {/*begin::SKU*/}
                                                <div className="text-muted fs-7">SKU: {product.sku}</div>
                                                {/*end::SKU*/}
                                            </div>
                                            </div>
                                        </td>
                                        {/*end::Product=*/}
                                        <td>{product.description}</td>
                                        <td>{product.size}</td>
                                        <td className='text-end'>{product.price_bulk}</td>
                                        {/*begin::Qty=*/}
                                        <td className="text-end pe-5" data-order={0}>
                                            <input
                                                type="text"
                                                className="w-100px form-control form-control-solid"
                                                placeholder=""
                                            />
                                        </td>
                                        {/*end::Qty=*/}
                                        <td className='text-end'>
                                        <a href='' className='btn btn-light-success'>Add to cart</a>
                                        </td>
                                        </tr>
                                    ))
                                }
                                {/*end::Table row*/}
                            </tbody>
                            </table>
                            <div>
                            {/*begin::Label*/}
                            <h2>Cart</h2>
                            {/* <label className="form-label">Add products to this order</label> */}
                            {/*end::Label*/}

                            <div className="row row-cols-1 row-cols-xl-2 row-cols-md-2 border border-dashed rounded pt-3 pb-1 px-2 mb-5 mh-300px overflow-scroll" id="kt_ecommerce_edit_order_selected_products">

                                <div className="col my-2" data-kt-ecommerce-edit-order-filter="product" data-kt-ecommerce-edit-order-id="product_1">
                                    <div className="d-flex align-items-center border border-dashed rounded p-3 bg-white">
                                        {/*begin::Thumbnail*/}
                                        <a href="/" className="symbol symbol-50px">
                                            <span className="symbol-label" style={{backgroundImage: 'url(media/stock/ecommerce/1.gif)'}} />
                                        </a>
                                        {/*end::Thumbnail*/}
                                        <div className="ms-5">
                                            {/*begin::Title*/}
                                            <a href="/" className="text-gray-800 text-hover-primary fs-5 fw-bolder">Product 1</a>
                                            {/*end::Title*/}
                                            {/*begin::Price*/}
                                            <div className="fw-bold fs-7">Price: ₹
                                            <span data-kt-ecommerce-edit-order-filter="price">222.00</span></div>
                                            {/*end::Price*/}
                                            {/*begin::SKU*/}
                                            <div className="text-muted fs-7">SKU: 01731001</div>
                                            {/*end::SKU*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="col my-2" data-kt-ecommerce-edit-order-filter="product" data-kt-ecommerce-edit-order-id="product_3">
                                    <div className="d-flex border border-dashed rounded p-3 bg-white">
                                        {/*begin::Thumbnail*/}
                                        <a href="/" className="symbol symbol-50px">
                                            <span className="symbol-label" style={{backgroundImage: 'url(media/stock/ecommerce/3.gif)'}} />
                                        </a>
                                        {/*end::Thumbnail*/}
                                        <div className="ms-5">
                                            {/*begin::Title*/}
                                            <a href="/" className="text-gray-800 text-hover-primary fs-5 fw-bolder">Product 3</a>
                                            {/*end::Title*/}
                                            {/*begin::Price*/}
                                            <div className="fw-bold fs-7">Price: ₹
                                            <span data-kt-ecommerce-edit-order-filter="price">136.00</span></div>
                                            {/*end::Price*/}
                                            {/*begin::SKU*/}
                                            <div className="text-muted fs-7">SKU: 04809005</div>
                                            {/*end::SKU*/}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/*begin::Total price*/}
                            <div className="fw-bolder fs-4">Total Cost: ₹
                                <span id="kt_ecommerce_edit_order_total_price">0.00</span>
                            </div>
                            {/*end::Total price*/}
                        </div>
                            {/*end::Input group*/}

                        </div>
                    </div>
                </div>


                <div className="d-flex justify-content-end">
                    {/*begin::Button*/}
                    <a href="#" id="kt_ecommerce_edit_order_cancel" className="btn btn-light me-5">Cancel</a>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                    <button type="submit" id="kt_ecommerce_edit_order_submit" className="btn btn-success">
                        <span className="indicator-label">Checkout</span>
                        <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                    </button>
                    {/*end::Button*/}
                </div>
                {/* </div> */}
            </div>
            {/* end::Main column */}
        </form>
        </>
    )
}