import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {CategoriesTable} from './table/CategoriesTable'
import {CategoryEditModal} from './category-edit-modal/CategoryEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { AdminDefaultLayoutConfig, LayoutContext} from '../../../../_metronic/layout/core'
import { useContext, useEffect } from 'react'
import { useAuth } from '../../auth'

const UsersList = () => {
    const {itemIdForUpdate} = useListView()
    const {currentUser} = useAuth()
    const { setLayout } = useContext(LayoutContext);
    useEffect(() => {
        setLayout(AdminDefaultLayoutConfig)
        if(currentUser?.role === 'admin'){
            document.body.classList.add('aside-enabled')
        }
    },[]);
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <CategoriesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CategoryEditModal />}
    </>
  )
}

const OrdersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OrdersListWrapper}
