import {useMemo, useState} from 'react'
import {useTable} from 'react-table'
// import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
// import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData} from '../core/QueryResponseProvider'
import {categoriesColumns} from './columns/_columns'
// import {Category} from '../core/_models'
// import {ProductsListLoading} from '../components/loading/ProductsListLoading'
// import {ProductsListPagination} from '../components/pagination/ProductsListPagination'
import {KTCardBody} from '../../../../_metronic/helpers'
import { useCart } from 'react-use-cart'

const CartTable = () => {
    const {
        isEmpty,
        // totalUniqueItems,
        items,
        // updateItemQuantity,
        removeItem,
      } = useCart();
  const users = useQueryResponseData()
//   const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => categoriesColumns, [])
  const {getTableProps, getTableBodyProps} = useTable({
    columns,
    data,
  })
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
    <form className='form d-flex flex-column flex-lg-row' action='/order/checkout'>
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>S.No</th>
              <th>Product</th>
              <th>Size</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Subtotal</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {items.map((item, index) => (
                <tr key={item.id}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td>{item.size}</td>
                    <td>{item.quantity}</td>
                    <td>{item.rate}</td>
                    <td>
                        {
                            item.quantity && item.quantity > 0 && item.quantity  * item.rate
                        }
                    </td>
                    <td>
                        <button className='btn btn-danger' onClick={() => removeItem(item.id)}>&times;</button>
                    </td>
                </tr>
                ))}
          </tbody>
        </table>
      </div>
        {
            !isEmpty &&
            <div className='d-flex justify-content-end'>
                <button
                        type='submit'
                        className='btn btn-success'
                        data-kt-users-modal-action='submit'
                        disabled={isSubmitting}
                    >
                        <span className='indicator-label'>Checkout</span>
                        {(isSubmitting) && (
                        <span className='indicator-progress'>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button>
            </div>
        }
    </KTCardBody>
    </form>
    </>
  )
}

export {CartTable}
