import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ProductsListWrapper } from './product-list/ProductList'
import ProductForm from './product-form/ProductForm'
import ProductEditForm from './product-edit/ProductEditForm'

const productBreadCrumbs: Array<PageLink> = [
  {
    title: 'Products',
    path: '/catalog/products/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <AccountHeader /> */}
            <Outlet />
          </>
        }
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={productBreadCrumbs}>Overview</PageTitle>
              {/* <CategoryList /> */}
              <ProductsListWrapper />
            </>
          }
        />
        <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={productBreadCrumbs}>Create Product</PageTitle>
              <ProductForm />
            </>
          }
        />
        <Route
          path='edit/:product_id'
          element={
            <>
              <PageTitle breadcrumbs={productBreadCrumbs}>Edit Product</PageTitle>
              <ProductEditForm />
            </>
          }
        />
        <Route index element={<Navigate to='/catalog/products/list' />} />
      </Route>
    </Routes>
  )
}

export default ProductPage
