import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
// import { categoriesModule } from '../../../../@fake-db/modules/categories'
import { useAuth } from '../../../../app/modules/auth';
// let categories = categoriesModule.categoriesList;
export function MenuInner() {
    const {currentUser} = useAuth()
    const intl = useIntl()
    const categories = currentUser?.categories
    return (
        <>
        <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
        {
            categories && categories.map(category => (
                <MenuItem key={category.id} title={category.name} to={`/products/${category.id}`} />
            ))
        }
        </>
    )
}
