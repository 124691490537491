// import { Link } from 'react-router-dom'
// import {KTSVG} from '../../../../../_metronic/helpers'
// import {useListView} from '../../core/ListViewProvider'
// import {UsersListFilter} from './UsersListFilter'

const ProductsListToolbar = () => {
//   const {setItemIdForUpdate} = useListView()
//   const openAddUserModal = () => {
//     setItemIdForUpdate(null)
//   }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <Link type='button' to={'/catalog/products/create'} className='btn btn-primary' >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Product
      </Link> */}
      {/* end::Add user */}
    </div>
  )
}

export {ProductsListToolbar}
