import FsLightbox from 'fslightbox-react'
import {FC, useState} from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

type Props = {
  name?: string
  product?: any
}

const ProductNameCell: FC<Props> = ({name, product}) => {
    const [toggler, setToggler] = useState(false);
    // console.log(product.photo_full_link);
    return(
//   <div className='badge badge-light fw-bolder'>{name}</div>
    <div className="d-flex align-items-center">
        {/*begin::Thumbnail*/}
        <button type='button' onClick={() => setToggler(!toggler)} className="btn btn-icon symbol symbol-50px overlay">
            <div className="overlay-layer card-rounded bg-dark bg-opacity-25 shadow">
                <i className="bi bi-eye-fill text-white fs-2x"></i>
            </div>
            <span className="symbol-label" style={{backgroundImage: `url('${toAbsoluteUrl(product.photo_full_link)}'`}} />
        </button>
        <FsLightbox
            toggler={toggler}
            sources={[
                product.photo_full_link
            ]}
            type="image"
            types={['image']}
            key={product.id}

        />
        {/*end::Thumbnail*/}
        <div className='ms-5'>
            {name}
        </div>
    </div>
)}

export {ProductNameCell}
