// @ts-nocheck
import {Column} from 'react-table'
import { CategoryActionsCell } from './CategoryActionsCell'
import { CategoryCustomHeader } from './CategoryCustomHeader'
import { OrderStatusCell } from './OrderStatusCell'
// import { CategoryNameCell } from './CategoryNameCell'

const categoriesColumns: ReadonlyArray<Column<User>> = [
    {
      Header: (props) => <CategoryCustomHeader tableProps={props} title='Order ID' className='min-w-125px' />,
      accessor: 'id',
    },
    {
      Header: (props) => <CategoryCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
      accessor: 'customer.name',
    },
    {
      Header: (props) => <CategoryCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
      accessor: 'customer.email',
    },
    {
      Header: (props) => <CategoryCustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
      accessor: 'total_price',
    },
    {
      Header: (props) => <CategoryCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
      id: 'status',
      Cell: ({...props}) => <OrderStatusCell order_status={props.data[props.row.index].order_status} status={props.data[props.row.index].status} />,
    },
    {
        Header: (props) => <CategoryCustomHeader tableProps={props} title='Date' className='min-w-125px' />,
        accessor: 'order_date',
      },
    {
        Header: (props) => (
          <CategoryCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <CategoryActionsCell id={props.data[props.row.index].id} />,
      },
  ]

  export {categoriesColumns}