import {Suspense, useState} from 'react'
import {Outlet} from 'react-router-dom'
import { CartProvider } from 'react-use-cart'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import AppCartContext from './pages/products/components/AppCartContext'

const App = () => {
    const [selectedSize, setSelectedSize] = useState<string>('');
    const [selectedQty, setSelectedQty] = useState<number>(1);

    const userCartActions = {
        selectedSize: selectedSize,
        selectedQty: selectedQty,
        setSelectedSize,
        setSelectedQty
    };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <AppCartContext.Provider value={userCartActions}>
            <I18nProvider>
                <LayoutProvider>
                <CartProvider>
                    <AuthInit>
                        <Outlet />
                        <MasterInit />
                    </AuthInit>
                </CartProvider>
                </LayoutProvider>
            </I18nProvider>
        </AppCartContext.Provider>
    </Suspense>
  )
}

export {App}
