import {FC, useContext} from 'react'
import {ID} from '../../../../../_metronic/helpers'
import AppCartContext from '../../components/AppCartContext'
// import {useListView} from '../../core/ListViewProvider'

type Props = {
  id: ID
}

const ProductQtyInputCell: FC<Props> = ({id}) => {
    const {setSelectedQty, selectedQty} = useContext(AppCartContext);
  return (
    <div className='form-input form-input-solid'>
      <input
        className='form-control'
        type='number'
        // value={initSelectedQty}
        onChange={(event) => setSelectedQty(event.target.value)}
      />
    </div>
  )
}

export {ProductQtyInputCell}
