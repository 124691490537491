/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import { useCart } from 'react-use-cart'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID} from '../../../../../_metronic/helpers'
import AppCartContext from '../../components/AppCartContext'

type Props = {
  id: ID
  product:any
}

const ProductActionsCell: FC<Props> = ({id, product}) => {
    const { addItem } = useCart();
    const { selectedSize, selectedQty, setSelectedQty, setSelectedSize } = useContext(AppCartContext);

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const addToCart = () => {
        const selectedItem = {
            id: `${product.id}-${selectedSize}`,
            product_id: product.id,
            name: product.name,
            size: selectedSize,
            sku: product.sku,
            price: product.post_tax_amount,
            tax: product.tax,
            tax_amount: product.tax_amount,
            rate: product.price,
          };
          addItem(selectedItem, Number(selectedQty));
          setSelectedQty(1)
          setSelectedSize('')
    }

    return (
        <>
            <a href="#" className="btn btn-light-success" onClick={addToCart}>Add to Cart</a>
        </>
    )
}

export {ProductActionsCell}
