import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProductsListHeader} from './components/header/ProductsListHeader'
import {ProductsTable} from './table/ProductsTable'
// import {CategoryEditModal} from './category-edit-modal/CategoryEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const ProductsList = () => {
//   const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ProductsListHeader />
        <ProductsTable />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <CategoryEditModal />} */}
    </>
  )
}

const ProductsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProductsListWrapper}
