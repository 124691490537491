/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../core/_requests'
import {Link} from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2'

const initialValues = {
  firstname: '',
  lastname: '',
  companyName: '',
  gstNo: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  contact_no: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip_code: '',
  country: '',
}

const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required'),
    companyName: Yup.string()
        .required('Company Name is required'),
    gstNo: Yup.string()
        .required('GST No is required'),
    address_1: Yup.string()
        .required('Address Line 1 is required'),
    address_2: Yup.string()
        .required('Address Line 2 is required'),
    city: Yup.string()
        .required('City is required'),
    state: Yup.string()
        .required('State is required'),
    country: Yup.string()
        .required('Country is required'),
    zip_code: Yup.string()
        .required('Zip Code is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    contact_no: Yup.string()
        .min(10, 'Minimum 10 symbols')
        .max(10, 'Maximum 10 symbols')
        .required('Contact No is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        }),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
    const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword,
          values.contact_no,
          values.companyName,
          values.gstNo,
          values.address_1,
          values.address_2,
          values.city,
          values.state,
          values.zip_code,
          values.country
        )
        saveAuth(auth)
        Swal.fire({
            title: "Success!",
            text: "Successfully registered!",
            icon: "success",
            // button: "Close",
        }).then(function() {
            navigate('/')
        });
        setLoading(false)
        setSubmitting(false)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div className='w-100 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>

    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Sign In
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}


      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
        <div className='fs-3 fw-bold mb-3'>Customer Details</div>
      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='required class="form-label fw-bolder text-dark fs-6'>Name</label>
          <input
            placeholder='Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='required form-label fw-bolder text-dark fs-6'>Contact No</label>
            <input
              placeholder='Contact No'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contact_no')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.contact_no && formik.errors.contact_no,
                },
                {
                  'is-valid': formik.touched.contact_no && !formik.errors.contact_no,
                }
              )}
            />
            {formik.touched.contact_no && formik.errors.contact_no && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contact_no}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='required form-label fw-bolder text-dark fs-6'>Company Name</label>
          <input
            placeholder='Company Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.companyName && formik.errors.companyName,
              },
              {
                'is-valid': formik.touched.companyName && !formik.errors.companyName,
              }
            )}
          />
          {formik.touched.companyName && formik.errors.companyName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='required form-label fw-bolder text-dark fs-6'>GST No</label>
            <input
              placeholder='GST No'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('gstNo')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.gstNo && formik.errors.gstNo,
                },
                {
                  'is-valid': formik.touched.gstNo && !formik.errors.gstNo,
                }
              )}
            />
            {formik.touched.gstNo && formik.errors.gstNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.gstNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
            <label className='required form-label fw-bolder text-dark fs-6'>Address 1</label>
            <input
            placeholder='Address 1'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('address_1')}
            className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.address_1 && formik.errors.address_1},
                {
                'is-valid': formik.touched.address_1 && !formik.errors.address_1,
                }
            )}
            />
            {formik.touched.address_1 && formik.errors.address_1 && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.address_1}</span>
                </div>
            </div>
            )}
        </div>
        <div className='col-xl-6'>
            <label className='required form-label fw-bolder text-dark fs-6'>Address 2</label>
            <input
            placeholder='Address 2'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('address_2')}
            className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.address_2 && formik.errors.address_2},
                {
                'is-valid': formik.touched.address_2 && !formik.errors.address_2,
                }
            )}
            />
            {formik.touched.address_2 && formik.errors.address_2 && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.address_2}</span>
                </div>
            </div>
            )}
        </div>
      </div>

      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
            <label className='required form-label fw-bolder text-dark fs-6'>City</label>
            <input
            placeholder='City'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('city')}
            className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.city && formik.errors.city},
                {
                'is-valid': formik.touched.city && !formik.errors.city,
                }
            )}
            />
            {formik.touched.city && formik.errors.city && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.city}</span>
                </div>
            </div>
            )}
        </div>
        <div className='col-xl-6'>
            <label className='required form-label fw-bolder text-dark fs-6'>State</label>
            <input
            placeholder='State'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('state')}
            className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.state && formik.errors.state},
                {
                'is-valid': formik.touched.state && !formik.errors.state,
                }
            )}
            />
            {formik.touched.state && formik.errors.state && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.state}</span>
                </div>
            </div>
            )}
        </div>
      </div>

      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
            <label className='required form-label fw-bolder text-dark fs-6'>Zip Code</label>
            <input
            placeholder='Zip Code'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('zip_code')}
            className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.zip_code && formik.errors.zip_code},
                {
                'is-valid': formik.touched.zip_code && !formik.errors.zip_code,
                }
            )}
            />
            {formik.touched.zip_code && formik.errors.zip_code && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.zip_code}</span>
                </div>
            </div>
            )}
        </div>
        <div className='col-xl-6'>
            <label className='required form-label fw-bolder text-dark fs-6'>Country</label>
            <input
            placeholder='Country'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('country')}
            className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.country && formik.errors.country},
                {
                'is-valid': formik.touched.country && !formik.errors.country,
                }
            )}
            />
            {formik.touched.country && formik.errors.country && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.country}</span>
                </div>
            </div>
            )}
        </div>
      </div>

      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='required form-label fw-bolder text-dark fs-6'>Email (username)</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='required form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='required form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex justify-content-end'>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-light me-5'
          >
            Cancel
          </button>
        </Link>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-primary mb-5'
          disabled={formik.isSubmitting || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
    </div>
  )
}
