/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {
//   MixedWidget2,
//   MixedWidget10,
//   MixedWidget11,
  ListsWidget2,
//   ListsWidget3,
  ListsWidget4,
//   ListsWidget5,
  ListsWidget6,
  TablesWidget5,
//   TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>

<div className="d-flex flex-column flex-center text-center p-10">
  {/*begin::Wrapper*/}
  <div className="card card-flush w-md-650px py-5">
    <div className="card-body py-10 py-lg-10">
      {/*begin::Logo*/}
      <div className="mb-7">
        <a href="../../demo1/dist/index.html">
          <img alt="Logo" src="/media/logos/logo.jpg" className="h-40px" />
        </a>
      </div>
      {/*end::Logo*/}
      {/*begin::Title*/}
      <h1 className="fw-bolder text-gray-900 mb-5">Welcome to Muthu Quality Labels</h1>
      {/*end::Title*/}
      {/*begin::Text*/}
      <div className="fw-semibold fs-6 text-gray-500 mb-7">A trusted textile label manufacturer</div>
      {/*end::Text*/}
      {/*begin::Link*/}
      <div className="mb-0">
        <Link to={'/products/1'} className="btn btn-sm btn-success">Explore E-Portal</Link>
      </div>
      {/*end::Link*/}
    </div>
  </div>
  {/*end::Wrapper*/}
</div>

  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
