import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_FORM_DATA = `${API_URL}/products/create`

const initFormData = (query: string): Promise<AxiosResponse> => {
    return axios
      .get(`${GET_FORM_DATA}?${query}`)
      .then((d: AxiosResponse<AxiosResponse>) => d.data)
}
export {initFormData}