// @ts-nocheck
import {Column} from 'react-table'
import { ProductActionsCell } from './ProductActionsCell'
import { ProductAttributeCell } from './ProductAttributeCell'
import { ProductCustomHeader } from './ProductCustomHeader'
import { ProductNameCell } from './ProductNameCell'
import { ProductQtyInputCell } from './ProductQtyInputCell'
// import { ProductNameCell } from './ProductNameCell'
// import { ProductSelectionCell } from './ProductSelectionCell'
// import { ProductSelectionHeader } from './ProductSelectionHeader'

const categoriesColumns: ReadonlyArray<Column<User>> = [
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
      accessor: 'name',
      Cell: ({...props}) => <ProductNameCell product={props.data[props.row.index]} name={props.data[props.row.index].name} />
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Design Code' className='min-w-125px' />,
      accessor: 'sku',
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Description' className='min-w-125px' />,
      accessor: 'description',
    },
    {
        Header: (props) => <ProductCustomHeader tableProps={props} title='Price' className='min-w-125px' />,
        accessor: 'price',
    },
    {
        Header: (props) => <ProductCustomHeader tableProps={props} title='Size' className='min-w-125px' />,
        //   accessor: 'original.attributes',
        id: 'attribute',
        Cell: ({...props}) => <ProductAttributeCell attributes={props.data[props.row.index].attributes} id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => <ProductCustomHeader tableProps={props} title='Qty' className='min-w-100px w-150px' />,
        //   accessor: 'qty',
        id: 'qty',
        Cell: ({...props}) => <ProductQtyInputCell id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => (
          <ProductCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <ProductActionsCell product={props.data[props.row.index]} id={props.data[props.row.index].id} />,
      },
  ]

  export {categoriesColumns}