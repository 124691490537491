import {FC} from 'react'

type Props = {
  status?: string,
  order_status?: string
}

const OrderStatusCell: FC<Props> = ({status, order_status}) => {
    console.log('status', `${status}-${order_status}`)
    return(
    status === "0" ? <div className='badge badge-light-warning fw-bolder'>New Order</div>
      : status === "1" ? <div className='badge badge-light-primary fw-bolder'>Processing</div>
      : status === "2" ? <div className='badge badge-light-success fw-bolder'>Completed</div>
      : status === "3" ? <div className='badge badge-light-danger fw-bolder'>Cancelled</div>
      : null
    )
}

export {OrderStatusCell}
