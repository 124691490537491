import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react'
import Select from 'react-select'
import Swal from 'sweetalert2';
import { ProductsListLoading } from '../product-list/components/loading/ProductsListLoading';
import {initFormData} from './core/_requests'

interface ProductAttribute {
    attributeValue : string,
}
export default function ProductForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buyers, setBuyers] = useState([]);
    const [product_customers, setProductCustomers] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [formErrors, setFormErrors] = useState<any>({});
    const [selectedImage, setSelectedImage] = useState('/media/svg/avatars/blank.svg');
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [sku, setSku] = useState('');
    const [product_name, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [price_bulk, setBulkPrice] = useState('');
    const [tax, setTax] = useState('');
    const [status, setStatus] = useState('published');
    const [category_id, setCategoryId] = useState('');
    const [activeTab, setActiveTab] = useState('general');
    const [inputFields, setInputFields] = useState([{
        attributeValue:'',
    } ]);

    useEffect(() => {
        setIsLoading(true);
        const response = initFormData('');
        response.then(result => {
            console.log(result.data);
            setBuyers(result.data.buyers);
            setCategories(result.data.categories);
            setIsLoading(false);
        })
        .catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }, []);

    const handleSetImage = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if(files && files?.length > 0){
            setSelectedImage(URL.createObjectURL(files[0]))
            setSelectedFile(files[0])
        }
        // console.log('selectedImage', selectedImage);
        // console.log('selectedFile', selectedFile);
    }

    const handleTabClick = (value: string) => {
        if (value === activeTab) {
          return;
        }
        setActiveTab(value);
    };

    const addInputField = () => {
        setInputFields([...inputFields, {
            attributeValue:'',
        } ])
    }

    const removeInputFields = (index :number)=>{
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
   }


    const handleChange = (index :number, event: ChangeEvent<HTMLInputElement>)=>{
        console.log(event.target.name);
        const { name, value } = event.target;
        const list = [...inputFields];
        list[index][name as keyof ProductAttribute] = value;
        setInputFields(list);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        const API_URL = process.env.REACT_APP_THEME_API_URL;
        setLoading(true);
        const formData = new FormData();
        formData.append("sku", sku);
        formData.append("name", product_name);
        formData.append("description", description);
        formData.append("category_id", category_id);
        formData.append("price", price);
        formData.append("price_bulk", price_bulk);
        formData.append("tax", tax);
        formData.append("status", status);
        formData.append("selectedFile", selectedFile);
        inputFields.map((item, i) => formData.append(`attribute_values[${i}]`, item.attributeValue));
        product_customers.map((item, i) => formData.append(`product_users[${i}]`, item.value));
        axios({
            method: 'POST',
            url: `${API_URL}/products/store`,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        }).then((result: any) => {
            console.log('result', result);
            setLoading(false);
            if(result.data.success){
                Swal.fire({
                    title: "Success!",
                    text: "Product successfully updated!",
                    icon: "success",
                    // button: "Close",
                });
            }
            else{
                Swal.fire({
                    title: result.message,
                    html: 'Sorry, looks like there are some errors detected, please try again.</p>',
                    icon: "error",
                    // button: "Close",
                });
                setFormErrors(result.data.data);
                console.log(formErrors);
            }
            //access the results here....
        });
    }

    const onSelectChange = (value: any) => {
        setProductCustomers(value);
    }

  return (
    <>
    {/*begin::Form*/}
    <form id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={onSubmit}>
    {/*begin::Aside column*/}
    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
        {/*begin::Thumbnail settings*/}
        <div className="card card-flush py-4">
        {/*begin::Card header*/}
        <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
            <h2>Thumbnail</h2>
            </div>
            {/*end::Card title*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body text-center pt-0">
            {/*begin::Image input*/}
            <div className="image-input image-input-empty" data-kt-image-input="true" style={{backgroundImage: `url(${selectedImage})`}}>
            {/*begin::Image preview wrapper*/}
            <div className="image-input-wrapper w-125px h-125px" />
            {/*end::Image preview wrapper*/}
            {/*begin::Edit button*/}
            <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-dismiss="click" title="Change avatar">
                <i className="bi bi-pencil-fill fs-7" />
                {/*begin::Inputs*/}
                <input type="file" name="avatar" accept=".png, .jpg, .jpeg"
                    onChange={e => handleSetImage(e)} />
                <input type="hidden" name="avatar_remove" />
                {/*end::Inputs*/}
            </label>
            {/*end::Edit button*/}
            {/*begin::Cancel button*/}
            <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" data-bs-dismiss="click" title="Cancel avatar">
                <i className="bi bi-x fs-2" />
            </span>
            {/*end::Cancel button*/}
            {/*begin::Remove button*/}
            <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-dismiss="click" title="Remove avatar">
                <i className="bi bi-x fs-2" />
            </span>
            {/*end::Remove button*/}
            </div>
            {/*end::Image input*/}

            {/*begin::Description*/}
            <div className="text-muted fs-7">Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted</div>
            {/*end::Description*/}
            {
                formErrors && formErrors.selectedFile &&
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    <span role='alert'>{formErrors.selectedFile}</span>
                    </div>
                </div>
            }
        </div>
        {/*end::Card body*/}
        </div>
        {/*end::Thumbnail settings*/}
        {/*begin::Status*/}
        <div className="card card-flush py-4">
        {/*begin::Card header*/}
        <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
            <h2>Status</h2>
            </div>
            {/*end::Card title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
            <div className="rounded-circle bg-success w-15px h-15px" id="kt_ecommerce_add_product_status" />
            </div>
            {/*begin::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
            {/*begin::Select2*/}
            <select onChange={(event) => setStatus(event.target.value)} value={status} className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_product_status_select">
            <option value="published">Published</option>
            <option value="draft">Draft</option>
            <option value="inactive">Inactive</option>
            </select>
            {/*end::Select2*/}
            {/*begin::Description*/}
            <div className="text-muted fs-7">Set the product status.</div>
            {/*end::Description*/}
            {/*begin::Datepicker*/}
            <div className="d-none mt-10">
            <label htmlFor="kt_ecommerce_add_product_status_datepicker" className="form-label">Select publishing date and time</label>
            <input className="form-control" id="kt_ecommerce_add_product_status_datepicker" placeholder="Pick date & time" />
            </div>
            {/*end::Datepicker*/}
        </div>
        {/*end::Card body*/}
        </div>
        {/*end::Status*/}
        {/*begin::Category & tags*/}
        <div className="card card-flush py-4">
        {/*begin::Card header*/}
        <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
            <h2>Product Details</h2>
            </div>
            {/*end::Card title*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
            {/*begin::Input group*/}
            {/*begin::Label*/}
            <label className="form-label">Categories</label>
            {/*end::Label*/}
            {/*begin::Select2*/}
            <select onChange={(event) => setCategoryId(event.target.value)} value={category_id} className="form-select mb-2" data-control="select2" data-placeholder="Select an option" data-allow-clear="true">
                <option>Select Category</option>
                {
                    categories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)
                }
            </select>
            {/*end::Select2*/}
            {/*begin::Description*/}
            <div className="text-muted fs-7 mb-7">Add product to a category.</div>
            {/*end::Description*/}
            {
                formErrors && formErrors.category_id &&
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    <span role='alert'>{formErrors.category_id}</span>
                    </div>
                </div>
            }
            {/*end::Input group*/}
        </div>
        {/*end::Card body*/}
        </div>
    </div>
    {/*end::Aside column*/}
    {/*begin::Main column*/}
    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
        {/*begin:::Tabs*/}
        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
        {/*begin:::Tab item*/}
        <li className="nav-item">
            <button type='button' className={`nav-link text-active-primary pb-4 ${activeTab === 'general'?'active':''}`} data-bs-toggle="tab" onClick={() => handleTabClick('general')}>General</button>
        </li>
        {/*end:::Tab item*/}
        {/*begin:::Tab item*/}
        <li className="nav-item">
            <button type='button' className={`nav-link text-active-primary pb-4 ${activeTab === 'advanced'?'active':''}`} data-bs-toggle="tab" onClick={() => handleTabClick('advanced')}>Advanced</button>
        </li>
        {/*end:::Tab item*/}
        </ul>
        {/*end:::Tabs*/}
        {/*begin::Tab content*/}
        <div className="tab-content" id='myTabContent'>
        {/*begin::Tab pane*/}
        <div className={`tab-pane fade ${activeTab === 'general' ? "active show" : ""}`} id="add_product_general" role="tabpanel">
            <div className="d-flex flex-column gap-7 gap-lg-10">
            {/*begin::General options*/}
            <div className="card card-flush py-4">
                {/*begin::Card header*/}
                <div className="card-header">
                <div className="card-title">
                    <h2>General</h2>
                </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Input group*/}
                    <div className="mb-10 fv-row">
                        {/*begin::Label*/}
                        <label className="required form-label">Design Code</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="text" name="sku" onChange={(event) => setSku(event.target.value)} value={sku} className="form-control mb-2" placeholder="SKU Number" />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">Enter the product Design Code.</div>
                        {/*end::Description*/}
                        {
                            formErrors && formErrors.sku &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                <span role='alert'>{formErrors.sku}</span>
                                </div>
                            </div>
                        }
                    </div>
                    {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-10 fv-row">
                    {/*begin::Label*/}
                    <label className="required form-label">Product Name</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input type="text" onChange={(event) => setProductName(event.target.value)} value={product_name} name="product_name" className="form-control mb-2" placeholder="Product name" />
                    {/*end::Input*/}
                    {/*begin::Description*/}
                    <div className="text-muted fs-7">A product name is required and recommended to be unique.</div>
                    {/*end::Description*/}
                    {
                        formErrors && formErrors.name &&
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert'>{formErrors.name}</span>
                            </div>
                        </div>
                    }
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div>
                    {/*begin::Label*/}
                    <label className="form-label">Description</label>
                    {/*end::Label*/}
                    <textarea
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    className='form-control mb-8'
                    rows={3}
                    placeholder='Description...'
                    ></textarea>
                    {/*begin::Description*/}
                    <div className="text-muted fs-7">Set a description to the product for better visibility.</div>
                    {/*end::Description*/}
                </div>
                {/*end::Input group*/}
                </div>
                {/*end::Card header*/}
            </div>
            {/*end::General options*/}

            {/*begin::Pricing*/}
            <div className="card card-flush py-4">
                {/*begin::Card header*/}
                <div className="card-header">
                <div className="card-title">
                    <h2>Pricing</h2>
                </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Price*/}
                    <div className="d-flex flex-wrap gap-5">
                    {/*begin::Input group*/}
                    <div className="mb-10 fv-row w-100 flex-md-root">
                        {/*begin::Label*/}
                        <label className="required form-label">Base Price</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="text" onChange={(event) => setPrice(event.target.value)} value={price} name="price" className="form-control mb-2" placeholder="Product price" />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">Set the product price.</div>
                        {/*end::Description*/}
                        {
                            formErrors && formErrors.price &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                <span role='alert'>{formErrors.price}</span>
                                </div>
                            </div>
                        }
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-10 fv-row w-100 flex-md-root">
                        {/*begin::Label*/}
                        <label className="form-label">Bulk Price</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="text" onChange={(event) => setBulkPrice(event.target.value)} value={price_bulk} name="price_bulk" className="form-control mb-2" placeholder="Product Bulk price" />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">Set the product bulk price.</div>
                        {/*end::Description*/}
                        {
                            formErrors && formErrors.price_bulk &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                <span role='alert'>{formErrors.price_bulk}</span>
                                </div>
                            </div>
                        }
                    </div>
                    {/*end::Input group*/}
                    {/*end::Price*/}

                    {/*begin::Input group*/}
                    <div className="mb-10 fv-row w-100 flex-md-root">
                        {/*begin::Label*/}
                        <label className="required form-label">Tax (%)</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="text" onChange={(event) => setTax(event.target.value)} value={tax} name="tax" className="form-control mb-2" placeholder="Product Tax" />
                        {/*end::Input*/}
                        {/*begin::Description*/}
                        <div className="text-muted fs-7">Set the product tax.</div>
                        {/*end::Description*/}
                        {
                            formErrors && formErrors.tax &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                <span role='alert'>{formErrors.tax}</span>
                                </div>
                            </div>
                        }
                    </div>
                    {/*end::Input group*/}
                    {/*end::Price*/}
                    </div>
                </div>
                {/*end::Card header*/}
            </div>
            {/*end::Pricing*/}
            </div>
        </div>
        {/*end::Tab pane*/}
        {/*begin::Tab pane*/}
        <div className={`tab-pane fade ${activeTab === 'advanced' ? "active show" : ""}`} id="add_product_advanced" role="tabpanel">
            <div className="d-flex flex-column gap-7 gap-lg-10">
            {/*begin::Inventory*/}
            <div className="card card-flush py-4">
                {/*begin::Card header*/}
                <div className="card-header">
                <div className="card-title">
                    <h2>Customers</h2>
                </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                <Select options={buyers} onChange={onSelectChange} value={product_customers} placeholder='Select Customers' isMulti={true}/>
                </div>
                {/*end::Card header*/}
            </div>
            {/*end::Inventory*/}
            {/*begin::Variations*/}
            <div className="card card-flush py-4">
                {/*begin::Card header*/}
                <div className="card-header">
                <div className="card-title">
                    <h2>Variations</h2>
                </div>
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                {/*begin::Input group*/}
                <div className="" data-kt-ecommerce-catalog-add-product="auto-options">
                    {/*begin::Label*/}
                    <label className="form-label">Add Product Variations</label>
                    {/*end::Label*/}
                    {/*begin::Repeater*/}
                    <div id="kt_ecommerce_add_product_options">
                    {/*begin::Form group*/}
                    <div className="form-group">
                        <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                            {
                                inputFields.map((data, index)=>{
                                    const {attributeValue}= data;
                                    return(
                                        <div data-repeater-item key={index} className="form-group d-flex flex-wrap align-items-center gap-5">
                                            {/*begin::Select2*/}
                                            <div className="w-100 w-md-200px">
                                            <select className="form-select" name="product_option" data-placeholder="Select a variation" data-kt-ecommerce-catalog-add-product="product_option">
                                                <option value="size">Size</option>
                                            </select>
                                            </div>
                                            {/*end::Select2*/}
                                            {/*begin::Input*/}
                                            <input type="text" className="form-control mw-100 w-200px" onChange={(event)=>handleChange(index, event)} value={attributeValue} name="attributeValue" placeholder="Variation" />
                                            {/*end::Input*/}
                                            {
                                                (inputFields.length !== 1)?
                                                <button type="button" onClick={() => removeInputFields(index)} data-repeater-delete className="btn btn-sm btn-icon btn-light-danger">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr088.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.5" x="7.05025" y="15.5356" width={12} height={2} rx={1} transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                                    <rect x="8.46447" y="7.05029" width={12} height={2} rx={1} transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                </button>
                                                :
                                                null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/*end::Form group*/}
                    {/*begin::Form group*/}
                    <div className="form-group mt-5">
                        <button type="button" onClick={addInputField} data-repeater-create className="btn btn-sm btn-light-primary">
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                        <span className="svg-icon svg-icon-2">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x={11} y={18} width={12} height={2} rx={1} transform="rotate(-90 11 18)" fill="currentColor" />
                            <rect x={6} y={11} width={12} height={2} rx={1} fill="currentColor" />
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}Add another variation</button>
                    </div>
                    {/*end::Form group*/}
                    </div>
                    {/*end::Repeater*/}
                </div>
                {/*end::Input group*/}
                </div>
                {/*end::Card header*/}
            </div>
            {/*end::Variations*/}
            </div>
        </div>
        {/*end::Tab pane*/}
        </div>
        {/*end::Tab content*/}
        <div className="d-flex justify-content-end">
        {/*begin::Button*/}
        <a href="../../demo1/dist/apps/ecommerce/catalog/products.html" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancel</a>
        {/*end::Button*/}
        {/*begin::Button*/}
        <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
            <span className="indicator-label">Save Changes</span>
            {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
        </button>
        {/*end::Button*/}
        </div>
    </div>
    {/*end::Main column*/}
    </form>
    {/*end::Form*/}
    {isLoading && <ProductsListLoading />}
    </>
  )
}

