// @ts-nocheck
import {Column} from 'react-table'
import { ProductActionsCell } from './ProductActionsCell'
import { ProductCustomHeader } from './ProductCustomHeader'
// import { ProductNameCell } from './ProductNameCell'
import { ProductSelectionCell } from './ProductSelectionCell'
import { ProductSelectionHeader } from './ProductSelectionHeader'

const categoriesColumns: ReadonlyArray<Column<User>> = [
    {
      Header: (props) => <ProductSelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({...props}) => <ProductSelectionCell id={props.data[props.row.index].id} />,
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
      accessor: 'name',
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='SKU' className='min-w-125px' />,
      accessor: 'sku',
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Category' className='min-w-125px' />,
      accessor: 'category.name',
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Price' className='min-w-125px' />,
      accessor: 'price',
    },
    {
      Header: (props) => <ProductCustomHeader tableProps={props} title='Tax(%)' className='min-w-125px' />,
      accessor: 'tax',
    },
    {
        Header: (props) => (
          <ProductCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <ProductActionsCell id={props.data[props.row.index].id} />,
      },
  ]

  export {categoriesColumns}